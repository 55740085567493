* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.text-align-center {
  text-align: center;
}

.MuiSvgIcon-root {
  vertical-align: middle;
}

/*Display*/
.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

/*Dimensions*/
.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-icon-button {
  width: 48px;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  height: 100vh;
}
